@import '../Base/index';

.@{PREFIX}TicketBar{
  > .wrapper {
    overflow: hidden;
    line-height: 28px;
    position: relative;
    > .left {
      float: left;
      > .icon {
        float: left;
        margin-right: 5px;
        &.green {
          > img.green {
            display: inline;
          }
        }
        &.gray {
          > img.gray {
            display: inline;
          }
        }
        > img {
          width: 18px;
          height: 18px;
          position: relative;
          bottom: 1px;
          display: none;
        }
      }
      > .title {
        float: left;
        font-size: 14px;
        font-weight: 500;
        > .name {
          float: left;
          color: @text-sub;
        }
        > .text {
          float: left;
          color: @text;
          font-family: Roboto sans-serif;
          line-height: 30px;
        }
      }
      > .label {
        margin-left: 8px;
        float: left;
        overflow: hidden;
        padding-top: 5px;
        box-sizing: border-box;
        > .item {
          line-height: 18px;
          float: left;
          font-size: 12px;
          height: 18px;
          padding: 0 4px;
          box-sizing: border-box;
          color: rgba(0,0,0,0.75);
          background: #F7F7F7;
          border: 1px solid @text-sep;
          border-radius: 2px;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    > .right {
      // 上面的提单箭头的实现可能会把这块覆盖住
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      > .item {
        float: left;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        padding: 0 12px;
        border-radius: 4px;
        color: @text;
        background: white;
        border: 1px solid @text-sep;
        cursor: pointer;
        margin-right: 12px;
        user-select: none;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: @g4-active;
        }
        &.primary {
          background: @primary;
          color: white;
          border-color: transparent;
          &:hover {
            background: @g1-active;
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;