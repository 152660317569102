@import '../Base/index';

.@{PREFIX}PageFlow{
  > .wrapper {
    margin-bottom: 36px;
    > .top {
      display: flex;
      margin-bottom: 17px;
      > .left {
        flex: 0 0 440px;
        > .inner {
          > .measure {
            > .item {
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      > .right {
        flex: 1 0;
        > .inner {
          padding-left: 12px;
          box-sizing: border-box;
          > .todo {
            .f-flow-web-MyTodoV2 {
              height: 292px;
            }
          }
        }
      }
    }
    > .todo {
      margin-bottom: 12px;
      min-height: 80px;
    }
    > .toolbar {
    }
    > .main {
      margin-top: 12px;
      display: flex;
      &.hide {
        height: 0;
        overflow: hidden;
        margin-top: 0;
      }
      > .left {
        flex: 0 0 280px;
        width: 0;
      }
      > .right {
        flex: 1 0;
        padding-left: 12px;
        box-sizing: border-box;
        > .box {
          height: 238px;
          width: 100%;
          padding: 20px 36px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 1px solid #1C6CDC;
          background: #EEF6FF;
          &.green {
            border: 1px solid #17A369;
            background: #EDFAF3 ;
          }
          > .inner {
            width: 100%;
            position: relative;
            > .layout {
              position: absolute;
            }
          }
        }
      }
      > .full {
        flex: 1 0 100%;
        > .box {
          width: 100%;
          padding: 20px 36px;
          box-sizing: border-box;
          border: 1px solid @text-sep;
          border-radius: 4px;
          background: white;
          > .bar {
            margin-bottom: 12px;
          }
          > .inner {
            width: 100%;
            position: relative;
            > .layout {
              position: absolute;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;