@import '../Base/index';

.@{PREFIX}TicketBrand{
  > .wrapper {
    border: 1px solid @primary;
    width: 100%;
    height: 238px;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    background: #eef6ff;
    > .empty {
      text-align: center;
      line-height: 238px;
      > img {
        width: 96px;
        height: 96px;
      }
    }
    &.green {
      background: #edfaf3;
      border-color: #17A369;
      > .top {
        background: #17A369;
        > .left > .icon {
          background: #0E8759;
        }
        > .right > .label > .item {
          color: #0E8759;
          background: #D2FAE6;
        }
      }
    }
    > .top {
      overflow: hidden;
      height: 112px;
      background: @primary;
      color: white;
      cursor: pointer;
      > .left {
        float: left;
        width: 80px;
        padding-top: 20px;
        box-sizing: border-box;
        > .icon {
          margin: auto;
          width: 44px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: @g1-active;
          border-radius: 8px;
          > img {
            width: 36px;
            height: 36px;
          }
        }
      }
      > .right {
        margin-left: 80px;
        padding: 20px 0;
        padding-right: 15px;
        box-sizing: border-box;
        > .head {
          .ellipsis();
          word-break: break-all;
          margin-bottom: 8px;
          > .name {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
          }
          > .dot {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding: 0 5px;
            box-sizing: border-box;
          }
          > .number {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: Roboto sans-serif;
          }
        }
        > .label {
          overflow: hidden;
          margin-left: -2px;
          margin-top: -2px;
          height: 44px;
          overflow: hidden;
          > .item {
            margin: 2px;
            float: left;
            max-width: 180px;
            color: #1C6CDC;
            background: #DBEDFF;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            padding: 0 4px;
            box-sizing: border-box;
            border-radius: 2px;
            .ellipsis();
          }
        }
      }
    }
    > .bottom {
      > .inner {
        padding: 11px 20px;
        box-sizing: border-box;
        > .item {
          font-size: 12px;
          line-height: 12px;
          padding: 7px 0;
          box-sizing: border-box;
          > .name {
            float: left;
            width: 88px;
            font-weight: 400;
          }
          > .value {
            margin-left: 88px;
            font-family: Roboto sans-serif;
            color: rgba(0,0,0,0.95);
            font-weight: 500;
            .ellipsis();
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;