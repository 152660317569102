@import '../Base/index';

.@{PREFIX}Toolbar{
  > .wrapper {
    display: table;
    width: 100%;
    > .btn-group {
      float: left;
      overflow: hidden;
      > .btn {
        user-select: none;
        float: left;
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.13);
        cursor: pointer;
        &:hover {
          background: @g4-active;
        }
        &.true {
          background: @primary;
          color: white;
          border: 1px solid rgba(28,108,220,1);
          cursor: default;
        }
        &.left {
          border-radius: 4px 0 0 4px;
        }
        &.right {
          margin-left: -1px;
          border-radius: 0 4px 4px 0;
        }
      }
    }
    > .ticket {
      float: left;
      margin-left: 12px;
      > .label {
        float: left;
        height: 32px;
        line-height: 32px;
        color: @text-sub;
        background-color: rgba(0, 0, 0, 0.04);
        padding: 0 8px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 4px 0 0 4px;
      }
      > .select {
        float: left;
        margin-left: -1px;
        .ant-select-focused {
          .ant-select-selector {
            box-shadow: none !important;
            border: 1px solid @primary;
          }
        }
        .ant-select-selector {
          border-radius: 0 4px 4px 0;
          box-shadow: none !important;
          border: 1px solid rgba(0, 0, 0, 0.13);
        }
      }
    }
    > .new {
      float: left;
      margin-left: 12px;
      > .btn {
        user-select: none;
        overflow: hidden;
        background: @primary;
        padding: 0 12px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        &:hover {
          background: @g1-active;
        }
        > .icon {
          float: left;
          margin-right: 6px;
          > img {
            width: 14px;
            height: 14px;
            vertical-align: middle;
            position: relative;
            bottom: 1px;
          }
        }
        > .text {
          float: left;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

@primary-color: #1C6CDC;