@import '../Base/index';

.@{PREFIX}Search{
  > .wrapper {
    position: relative;
    line-height: normal;
    > .inner {
      border-radius: 14px;
      overflow: hidden;
      > input {
        background: #F7F7F7;
        width: 100%;
        border: none;
        outline: none;
        padding-left: 32px;
        box-sizing: border-box;
        height: 28px;
        line-height: 20px;
        font-size: 14px;
      }
    }
    > .icon {
      position: absolute;
      left: 8px;
      top: 5px;
      width: 16px;
      height: 16px;
      > img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@primary-color: #1C6CDC;