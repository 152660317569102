@import '../Base/index';

.@{PREFIX}EmptyBlock{
  > .wrapper {
    height: 350px;
    width: 100%;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.07);
    text-align: center;
    padding-top: 100px;
    box-sizing: border-box;
    > .img {
      > img {
        width: 100px;
      }
    }
    > .text {
      margin-top: 18px;
      color: @text-sub;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

@primary-color: #1C6CDC;