@import '../Base/index';

.@{PREFIX}TicketNodePrimary{
  width: 100%;
  min-width: 160px;
  height: 200px;
  position: relative;
  &.green {
    > .wrapper {
      > .head > .inner {
        background: #17A369;
        color: white;
        > .order  {
          color: #0E8759;
          border-color: #17A369;
          background: #D2FAE6;
        }
        > .arrow {
          background: #17A369;
        }
      }
    }
  }
  &.green-process {
    > .wrapper {
      > .head > .inner {
        background: #A6EDCA;
        color: #056E49;
        > .order  {
          color: #056E49;
          border-color: #A6EDCA;
          background: #EDFAF3;
        }
        > .arrow {
          background: #A6EDCA;
        }
      }
    }
  }
  &.blue-process {
    > .wrapper {
      > .head > .inner {
        background: #B0D6FF;
        color: #0A3C8C;
        > .order  {
          color: #0A3C8C;
          border-color: #B0D6FF;
          background: #EEF6FF;
        }
        > .arrow {
          background: #B0D6FF;
        }
      }
    }
  }
  &.gray {
    > .wrapper {
      > .head > .inner {
        background: #EAEAEA;
        color: rgba(0, 0, 0, 0.6);
        > .order  {
          color: rgba(0, 0, 0, 0.6);
          border-color: #EAEAEA;
          background: white;
        }
        > .arrow {
          background: #EAEAEA;
        }
      }
    }
  }
  > .arrow-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 100%;
    box-sizing: border-box;
    display: none;
    &.arrow {
      display: block;
    }
    &.empty-arrow {
      display: block;
    }
    > .outer {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: -50%;
      > .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scale(1,0.4);
        > .arrow {
          position: absolute;
          z-index: 4;
          width: 70%;
          left: 15%;
          top: 15%;
          height: 0;
          padding-top: 70%;
          transform: rotate(45deg);
          border-bottom-right-radius: 4px;
          border: 2px solid @text-sep;
          background: white;
        }
      }
    }
  }
  > .wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    > .head-shadow {
      width: 100%;
      flex: 0 0 16px;
    }
    > .head {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 32px;
      user-select: none;
      > .inner {
        width: calc(100% - 64px + 2px);
        height: 32px;
        line-height: 32px;
        background: @primary;
        color: white;
        position: absolute;
        left: 32px;
        > .order  {
          position: absolute;
          top: 0;
          left: -16px;
          color: @primary;
          width: 32px;
          height: 32px;
          line-height: 30px;
          border-radius: 50%;
          border: 2px solid @primary;
          background: #DBEDFF;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          font-family: Roboto sans-serif;
        }
        > .arrow {
          z-index: 1;
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          background: @primary;
          top: 4px;
          right: -11px;
          transform: rotate(45deg);
        }
        > .title {
          z-index: 2;
          position: relative;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          padding-left: 18px;
          box-sizing: border-box;
          .ellipsis();
        }
      }
    }
    > .content {
      background: white;
      border: 1px solid @text-sep;
      border-radius: 4px;
      flex: 1 0;
      padding-top: 24px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      &.footer-arrow {
        border-bottom: none;
      }
      &.footer-empty-arrow {
        border-bottom: none;
      }
      > .footer {
        flex: 0 0 28px;
        border-top: 1px solid @text-sep;
        box-sizing: border-box;
        &.empty {
          display: none;
        }
        &.normal {
          > .action.normal {
            display: block;
          }
        }
        &.arrow {
          > .action.arrow {
            display: block;
          }
        }
        &.empty-arrow {
          > .action {
            cursor: default;
            &:hover {
              > span {
                color: @text-weak;
              }
            }
          }
          > .action.empty-arrow {
            display: block;
            > span {
              color: @text-weak;
            }
          }
        }

        > .action {
          line-height: 28px;
          text-align: center;
          user-select: none;
          cursor: pointer;
          display: none;
          &.arrow {
            position: relative;
            &:hover {
              > span {
                color: @g1-active;
              }
            }
            > span {
              color: @primary;
            }
          }
          &:hover {
            > span {
              color: @g2-active;
            }
          }
          > span {
            font-size: 12px;
            color: @text-weak;
            font-weight: 500;
            &.icon {
              margin-right: 5px;
            }
            > img {
              width: 14px;
              height: 14px;
              vertical-align: middle;
              position: relative;
              bottom: 2px;
            }
          }
        }
      }
      > .inner {
        flex: 1 0;
        overflow: auto;
        height: 100%;
        &.highlight {
          > .item {
            cursor: pointer;
            &:hover {
              > .text {
                color: @primary;
              }
            }
          }
        }
        > .item {
          overflow: hidden;
          line-height: 28px;
          cursor: default;
          padding: 0 12px;
          box-sizing: border-box;
          &.selected {
            background: rgba(0,0,0,0.04);
            > .text {
              color: #1C6CDC !important;
            }
          }
          &:hover {
            background: rgba(0,0,0,0.04);
            > .action {
              display: block;
            }
          }
          > .icon {
            width: 14px;
            height: 14px;
            vertical-align: middle;
            position: relative;
            bottom: 2px;
            float: left;
            &.green {
              > img.green {
                display: inline;
              }
            }
            &.gray {
              > img.gray {
                display: inline;
              }
            }
            &.process {
              > img.process {
                display: inline;
              }
            }
            &.wait-upload {
              > img.wait-upload {
                display: inline;
              }
            }
            &.wait-review {
              > img.wait-review {
                display: inline;
              }
            }
            > img {
              width: 14px;
              height: 14px;
              display: none;
            }
          }
          > .action {
            float: right;
            width: 58px;
            font-size: 12px;
            text-align: right;
            color: @primary;
            user-select: none;
            display: none;
            &:hover {
              color: @g1-active;
            }
            > .icon {
              width: 19px;
              height: 28px;
              line-height: 28px;
              display: inline-block;
              text-align: center;
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
              > img {
                width: 16px;
                height: 16px;
                position: relative;
              }
            }
          }
          > .text {
            margin-left: 14px + 5px;
            margin-right: 58px;
            font-size: 14px;
            color: rgba(0,0,0,0.95);
            .ellipsis();
            &.gray {
              color: @text-sub;
            }
            &.action-list-0 {
              margin-right: 0
            }
            > div {
              .ellipsis();
            }
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;