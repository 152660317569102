@import '../Base/index';

.@{PREFIX}TicketLayout{
  > .wrapper {
    > .row {
      display: inline-flex;
      padding-bottom: 30px;
      &:last-child {
        padding-bottom: 0;
      }
      box-sizing: border-box;
      > .item {
        &.hidden {
          visibility: hidden;
        }
      }
    }
  }
}

@primary-color: #1C6CDC;