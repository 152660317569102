@import '../Base/index';

.@{PREFIX}MeasureCard{
  width: 100%;
  height: 140px;
  overflow: hidden;
  border: 1px solid @text-sep;
  border-radius: 4px;
  background: white;
  > .top-bar {
    width: 100%;
    height: 4px;
    background: @PRIMARY;
  }
  > .wrapper {
    padding: 12px 20px 16px 20px;
    box-sizing: border-box;

    > .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.95);
      padding-bottom: 20px;
      box-sizing: border-box;
    }
    > .content {
      display: flex;
      > .sep {
        flex: 0 0 1px;
        height: auto;
        padding-top: 15px;
        padding-bottom: 5px;
        box-sizing: border-box;
        > .inner {
          width: 100%;
          height: 100%;
          background: @text-sep;
        }
      }
      > .main {
        flex: 0 0 100px;
        width: 0;
        > .item {
          > .name {
            font-size: 12px;
            line-height: 12px;
            color: @text-sub;
            padding-bottom: 8px;
            box-sizing: border-box;
          }
          > .value {
            font-size: 44px;
            line-height: 44px;
            font-family: Roboto sans-serif;
            font-weight: 700;
            .ellipsis();
          }
        }
      }
      > .sub {
        flex: 2 0;
        display: flex;
        padding-left: 20px;
        padding-top: 17px;
        box-sizing: border-box;
        > .item {
          flex: 1 0;
          width: 0;
          > .name {
            font-size: 12px;
            color: @text-sub;
          }
          > .value {
            font-size: 24px;
            line-height: 24px;
            font-family: Roboto sans-serif;
            font-weight: 700;
            .ellipsis();
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;