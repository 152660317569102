@import '../Base/index';

.@{PREFIX}TicketArrow{
  width: 100%;
  height: 200px;
  text-align: center;
  > img {
    width: 24px;
    height: 24px;
    position: relative;
    top: 3px;
    &.true {
      transform: rotate(180deg);
    }
  }
}

@primary-color: #1C6CDC;