@import '../Base/index';

.@{PREFIX}CrudListForm{
  > .wrapper {
    &.horizontal {
      display: table;
      width: 100%;
      > .item {
        float: left;
        > .name {
          float: left;
          padding-right: 10px;
          box-sizing: border-box;
        }
        > .value {
          float: left;
        }
      }
    }

    > .group {
      display: flex;
      margin: 10px 0;
      > .item {
        flex: 1 0;
        line-height: 32px;
        > .name {
          width: 120px;
          text-align: right;
          float: left;
          > .star { color: red; margin-left: 3px; }
        }
        > .value {
          margin-left: 120px + 10px;
          > .ant-input {
            width: 100%;
          }
          > .ant-select {
            width: 100%;
          }
        }
      }
    }

    > .item {
      line-height: 32px;
      margin-bottom: 6px;
      &.submit {
        margin-top: 20px;
      }
      > .name {
        > .star { color: red; margin-left: 3px; }
        > img {
          margin-left: 5px;
          position: relative;
          bottom: 1px;
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
      }
      > .value {
      }
    }
  }
}

@primary-color: #1C6CDC;