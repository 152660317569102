@import '../Base/index';

.@{PREFIX}Header{
  color: @text;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid @text-sep;
  > .wrapper {
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    line-height: 48px;
    > .left {
      flex: 0 0 200px - 16px;
      > .logo {
        float: left;
        > img {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          position: relative;
          bottom: 2px;
        }
      }
      > .title {
        float: left;
        font-size: 16px;
        margin-left: 8px;
        font-weight: 400;
      }
    }
    > .right {
      flex: 0 0 370px;
      display: flex;
      > .user {
        flex: 1 0;
        width: 0;
        padding-left: 24px;
        box-sizing: border-box;
        > .avatar {
          float: left;
          > img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        > .name {
          margin-left: 30px;
          .ellipsis();
        }
      }
      > .query {
        flex: 0 0 200px;
        padding-top: 10px;
        box-sizing: border-box;
      }
    }
    > .middle {
      flex: 1 0;
      > .menu {
        overflow: hidden;
        > .item {
          float: left;
          padding: 0 16px;
          box-sizing: border-box;
          font-weight: 500;
          &.true {
            color: @primary;
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;