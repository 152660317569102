@PRIMARY: @primary-color;
@PREFIX: ~'f-flow-web-';

@primary: #1c6cdc;
@error: #ff5f57;
@success: #24bd78;
@warn: #f5ba31;

@text: rgba(0, 0, 0, 0.87);
@text-sub: rgba(0, 0, 0, 0.6);
@text-weak: rgba(0, 0, 0, 0.38);
@text-guide: rgba(0, 0, 0, 0.24);
@text-sep: rgba(0, 0, 0, 0.07);
@text-bg: #f7f7f7;

@g1: @primary;
@g1-active: #1253b5;
@g1-disabled: #b0d6ff;

@g2: @text;
@g2-active: rgba(0, 0, 0, 0.6);
@g2-active2: @primary;
@g2-disabled: rgba(0, 0, 0, 0.38);

@g3: @text-sub;
@g3-active: rgba(0, 0, 0, 0.87);
@g3-active2: @primary;
@g3-disabled: rgba(0, 0, 0, 0.38);

@g4: #ffffff;
@g4-active: rgba(0, 0, 0, 0.07);
@g4-disabled: rgba(0, 0, 0, 0.38);

.ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
