@import '../Base/index';

.@{PREFIX}PageDemo{
  height: 100vh;
  > .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    > .header {
      flex: 0 0 auto;
      background: white;
    }
    > .toolbar {
      flex: 0 0 52px;
      padding: 5px 0;
      box-sizing: border-box;
      > .inner {
        background: #ee4e2d;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 5px 24px;
        box-sizing: border-box;
        height: 100%;
      }
    }
    > .content {
      flex: 1 0;
      display: flex;
      overflow: hidden;
      background: #f7f7f7;
      padding: 20px 24px;
      box-sizing: border-box;
      > .left {
        flex: 1 0;
        height: 100%;
        overflow: auto;
      }
      > .right {
        flex: 0 0 260px;
        height: 100%;
        background: #1a4a67;
        color: white;
        overflow: auto;
        > .title {
          padding: 10px;
          box-sizing: border-box;
          font-weight: bold;
          border-bottom: 1px solid white;
        }
        > .content {
          > .item {
            padding: 10px;
            box-sizing: border-box;
            font-family: monospace;
            border-bottom: 1px dashed white;
            > .name {

            }
            > .value {
              > .param {
                margin: 0 3px;
                color: #1890ff;
                cursor: help;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;