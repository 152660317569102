@import '../Base/index';

.@{PREFIX}MyTodoV2{
  background: white;
  border: 1px solid @text-sep;
  border-radius: 4px;
  overflow: hidden;
  > .wrapper {
    > .top-bar {
      background: #F78745;
      width: 100%;
      height: 4px;
    }
    > .footer {
      text-align: right;
      padding: 16px 20px;
      box-sizing: border-box;
    }
    > .content {
      display: flex;
      padding: 0 21px;
      box-sizing: border-box;

      > .empty {
        text-align: center;
        flex: 1 0;
      }

      > .column {
        flex: 1 0;
        width: 0;
        padding: 0;
        box-sizing: border-box;
        border-right: 1px solid @text-sep;
        &:last-child {
          border-right: none;
        }
        > .item {
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          padding: 5px 15px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            background: rgba(0,0,0,0.04);
          }
          &.hidden {
            visibility: hidden;
          }
          .left {
            margin-right: 60px;
            .ellipsis();
            > .ticket {
              color: @text-sub;
              font-family: Roboto sans-serif;
            }
            > .title {
              margin-left: 8px;
              color: @text;
            }
          }
          .right {
            width: 60px;
            text-align: right;
            float: right;
            user-select: none;
            > .btn {
              color: @primary;
            }
          }
        }
      }
    }
    > .header {
      height: 46px;
      padding: 12px 22px 10px 22px;
      box-sizing: border-box;
      line-height: 24px;
      > .title {
        overflow: hidden;
        > .icon {
          float: left;
          width: 18px;
          > img {
            width: 18px;
            height: 18px;
            vertical-align: middle;
            position: relative;
            bottom: 1px;
          }
        }
        > .text {
          margin-left: 18px + 5px;
          font-size: 16px;
          > .text {
            color: @text;
            font-weight: 500;
          }
          > .number {
            font-family: Roboto sans-serif;
            color: @text-sub;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;