@import '../Base/index';

.@{PREFIX}TicketNode{
  width: 100%;
  min-width: 150px;
  height: 220px;
  overflow: hidden;
  border: 1px solid @text-sep;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  &.blue-process {
    > .head {
      color: @primary;
      background: #EEF6FF;
    }
  }
  &.blue {
    > .head {
      color: #0A3C8C;
      background: #B0D6FF;
    }
  }
  &.green-process {
    > .head {
      color: #0E8759;
      background: #EDFAF3;;
    }
  }
  &.green {
    > .head {
      color: #056E49;
      background: #A6EDCA;
    }
  }
  &.gray {
    > .head {
      color: @text-sub;
      background: #F7F7F7;
    }
  }
  > .head {
    flex: 0 0 34px;
    line-height: 34px;
    font-size: 14px;
    color: @primary;
    background: #EEF6FF;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid @text-sep;
    padding: 0 10px;
    box-sizing: border-box;
    .ellipsis();
  }
  > .content {
    flex: 1 0;
    overflow: auto;
    background: white;
    > .inner {
      padding: 6px 0;
      box-sizing: border-box;
      > .item {
        overflow: hidden;
        line-height: 28px;
        cursor: default;
        padding: 0 12px;
        box-sizing: border-box;
        &.gray {
          > .text {
            color: @text-sub;
          }
        }
        &:hover {
          background: rgba(0,0,0,0.04);
          > .action {
            display: block;
          }
        }
        > .icon {
          width: 14px;
          height: 14px;
          vertical-align: middle;
          position: relative;
          bottom: 2px;
          float: left;
          &.green {
            > img.green {
              display: inline;
            }
          }
          &.gray {
            > img.gray {
              display: inline;
            }
          }
          &.process {
            > img.process {
              display: inline;
            }
          }
          &.wait-upload {
            > img.wait-upload {
              display: inline;
            }
          }
          &.wait-review {
            > img.wait-review {
              display: inline;
            }
          }
          > img {
            width: 14px;
            height: 14px;
            display: none;
          }
        }
        > .action {
          float: right;
          width: 58px;
          font-size: 12px;
          text-align: right;
          color: @primary;
          user-select: none;
          display: none;
          &:hover {
            color: @g1-active;
          }
          > .icon {
            width: 19px;
            height: 28px;
            line-height: 28px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            > img {
              width: 16px;
              height: 16px;
              position: relative;
            }
          }
        }
        > .text {
          margin-left: 14px + 5px;
          margin-right: 58px;
          font-size: 14px;
          color: rgba(0,0,0,0.95);
          .ellipsis();
          &.action-list-0 {
            margin-right: 0
          }
        }
      }
    }
  }
}

@primary-color: #1C6CDC;